import React, { useState, useEffect } from 'react';
import firebase from 'firebase/app';
import Header from './Header';
import './Pricing.css';
import LogoTekt from './images/footer/tekt_ai.png';
import InstagramIcon from './images/footer/insta-icon.png';
import TwitterIcon from './images/footer/twitter-icon.png';
import LinkdinIcon from './images/footer/linkdin-icon.png';
import TiktokIcon from './images/footer/tiktok-icon.png';

function Pricing() {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    
    useEffect(() => {
        const unregisterAuthObserver = firebase.auth().onAuthStateChanged(
            (user) => setIsAuthenticated(!!user)
        );
        return () => unregisterAuthObserver();
    }, []);

    return (
        <div className="pricing-container">
            <Header isAuthenticated={isAuthenticated} />  {/* Pass isAuthenticated prop to Header */}

            <section className="pricing-content">
             <div className="unlock-features">
                <h1>Unlock full features</h1>
                <h5>Simply sign up to start using Ai Architekt</h5>
                </div>
                <div className="pricing-options">
                    <div className="pricingA-card" onClick={() => {/* Your subscription logic for sharing */}}>
                        <button className="Free-Plan">Free Access</button> 
                        <div className="share-social"></div>
                        <h3>Start with the basics</h3>
                        <p>Enjoy <span style={{color: '#df1be6'}}>complimentary</span> access to basic features such as Ai Acrhitekt chatbot as soon as you sign up!</p>
                    </div>
                    <div className="pricingB-card" onClick={() => {/* Your subscription logic for premium */}}>
                        <h3>Experience Design Magic✨<br/>(temporary free)
                        <br/>
                        $9.99 / month
                        </h3>
                        <ul>
                            <li><svg className="svg" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M20 6L9 17L4 12" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" fontFamily="Avenir"></path></svg>Unlimited Interior & Exterior Renders</li>
                            <li><svg className="svg" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M20 6L9 17L4 12" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" fontFamily="Avenir"></path></svg>Unlimited Architectural Responses</li>
                            <li><svg className="svg" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M20 6L9 17L4 12" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" fontFamily="Avenir"></path></svg>Unlimited Audio Responses</li>
                            <li><svg className="svg" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M20 6L9 17L4 12" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" fontFamily="Avenir"></path></svg>High Resolution Image Generator</li>
                        </ul>
                    </div>
                </div>
            </section>

            <footer className="footer-pricing">
                <div className="logo-footer">
                    <img src={LogoTekt} className="logo-footer" alt="Tekt Ai Logo" />
                </div>
                <div className="rights">
                    <p className="footer-sentence">Copyright © 2023 Tekt Ai. All Rights Reserved.</p>
                </div>
                <div className="socials">
                    <a href="https://instagram.com/tekt.ai" className="social-icon" target="_blank" rel="noopener noreferrer"><img src={InstagramIcon} alt="Instagram" /></a>
                    <a href="https://twitter.com/tekt_ai" className="social-icon" target="_blank" rel="noopener noreferrer"><img src={TwitterIcon} alt="Twitter" /></a>
                    <a href="https://www.linkedin.com/company/tekt-ai/" className="social-icon" target="_blank" rel="noopener noreferrer"><img src={LinkdinIcon} alt="Linkdin" /></a>
                    <a href="https://www.tiktok.com/company/tekt.ai/" className="social-icon" target="_blank" rel="noopener noreferrer"><img src={TiktokIcon} alt="Tiktok" width="20" height="20" /></a>
                </div>
            </footer>
        </div>
    );
}

export default Pricing;
