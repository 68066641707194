import React, { useEffect, useState } from 'react';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import Modal from 'react-modal';
import './SignUp.css';
import backIcon from './images/dashboard/go-back-icon.png';
import { useNavigate } from 'react-router-dom';

Modal.setAppElement('#root');

function SignUp({ modalIsOpen, closeModal: closeModalProp, openLoginModal }) {
  const navigate = useNavigate();
  const db = firebase.firestore();
  const [step, setStep] = useState("INITIAL");  // State to track sign-up step


  const closeModal = () => {
    closeModalProp(); // This calls the parent component's close function
    setStep("INITIAL");
};

  
  const handleSignUp = async (e) => {
    e.preventDefault();
    const firstName = e.target.elements.firstName.value;
    const lastName = e.target.elements.lastName.value;
    const email = e.target.elements.email.value;
    const password = e.target.elements.password.value;
  
    try {
      const { user } = await firebase.auth().createUserWithEmailAndPassword(email, password);
      await db.collection('users').doc(user.uid).set({
        firstName: firstName,
        lastName: lastName,
        email: email,
        paymentStatus: "free"
      });
  
      // alert("User created successfully"); // This might not execute if the redirection happens too quickly.
      closeModal();
      navigate('./Launcher'); 
    } catch (error) {
      alert(error.message);
    }
  }
  

  const handleGoogleSignUp = async () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    try {
      const result = await firebase.auth().signInWithPopup(provider);
      const { user } = result;
      await db.collection('users').doc(user.uid).set({
        firstName: user.displayName.split(' ')[0],
        lastName: user.displayName.split(' ')[1],
        email: user.email,
        paymentStatus: "free" 
      });
      closeModal();
      navigate('./Launcher'); 
    } catch (error) {
      alert(error.message);
    }
  }

  const handleRegisteredClick = () => {
    console.log("Before closeModal");
    closeModal();
    console.log("Before openLoginModal");
    openLoginModal();
    console.log("After openLoginModal");
};

return (
  <Modal
    isOpen={modalIsOpen}
    onRequestClose={closeModal}
    className="Signup-modal"
    overlayClassName="overlay"
  >
    {step === "INITIAL" && (
      <>
        <button type="button" className="close-button" onClick={closeModal}>X</button>
        <h8>Quick Begin Now! 🚀</h8>
        <div className="signup-buttons">
          <button className="signup-option" type="button" onClick={handleGoogleSignUp}>Continue with Google</button> {/* Directly call Google sign-up */}
          <button className="signup-option" type="button" onClick={() => setStep("EMAIL")}>Continue with Email</button>
        </div>
      </>
    )}

    {step === "EMAIL" && (
      <>
        <h2>Create an account</h2>
        <span onClick={handleRegisteredClick} className="registered-link-signup">Already Registered?</span>
        <form className="signup-form" onSubmit={handleSignUp}>
                <button className="close-button" onClick={closeModal}>X</button>
                <button className="back-button" onClick={() => setStep("INITIAL")}><img className="go-back-icon" src={backIcon} alt="Icon" /></button> {/* Back button */}
                <input name="firstName" type="text" placeholder="First Name" required />
                <input name="lastName" type="text" placeholder="Last Name" required />
                <input name="email" type="email" placeholder="Email" required />
                <input name="password" type="password" placeholder="Password" required />
                <div className="signup-buttons">
                  <button type="submit">Create Account</button>
                </div>
              </form>
            </>
          )}
  </Modal>
);
}

export default SignUp;
