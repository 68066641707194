import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import './Paymentsuccess.css';
import './LoadingSpinner.css';



function throttle(func, limit) {
    let inThrottle;
    return function() {
        const args = arguments;
        const context = this;
        if (!inThrottle) {
            func.apply(context, args);
            inThrottle = true;
            setTimeout(() => inThrottle = false, limit);
        }
    }
}

function Star(props) {
    const size = Math.random() * 20 + 5;
    return (
        <div 
            className="star" 
            style={{
                left: props.x - size / 2,
                top: props.y - size / 2,
                position: 'absolute',
                filter: 'drop-shadow(0 0 5px pink)',
                transform: `scale(${Math.random() + 0.7})`,
                opacity: Math.random() + 0.5
            }}
        >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white" width={size} height={size}><polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon></svg>
        </div>
    );
}

function Paymentsuccess() {
    const navigate = useNavigate();
    const db = firebase.firestore();
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [stars, setStars] = useState([]);
    useEffect(() => {
        // Set body overflow to hidden when component mounts
        document.body.style.overflow = 'hidden';

        // Reset body overflow when component unmounts
        return () => {
            document.body.style.overflow = '';
        };
    }, []);

    const updatePaymentStatus = async (userId) => {
        try {
            await db.collection('users').doc(userId).update({
                paymentStatus: "completed"
            });
            setLoading(false);
        } catch (error) {
            console.error("Error updating payment status:", error);
            setLoading(false);
        }
    };

    useEffect(() => {
        const unsubscribe = firebase.auth().onAuthStateChanged(currentUser => {
            setUser(currentUser);
            if (currentUser) {
                updatePaymentStatus(currentUser.uid);
            } else {
                setLoading(false);
            }
        });

        return () => unsubscribe();
    }, []);

    useEffect(() => {
        const handleMove = (e) => {
            const isTouchEvent = e.touches && e.touches.length;
            const x = isTouchEvent ? e.touches[0].clientX : e.clientX;
            const y = isTouchEvent ? e.touches[0].clientY : e.clientY;

            const newStar = { x: x, y: y, id: Date.now() };
            setStars((prevStars) => [newStar, ...prevStars]);

            if (stars.length > 10) {
                setStars((prevStars) => prevStars.slice(0, 10));
            }
        };

        const throttledMove = throttle(handleMove, 100);

        document.addEventListener("mousemove", throttledMove);
        document.addEventListener("touchmove", throttledMove);

        return () => {
            document.removeEventListener("mousemove", throttledMove);
            document.removeEventListener("touchmove", throttledMove);
        };
    }, [stars]);

    const handleContinue = () => {
        navigate('/Launcher');
    };

    if (loading) {
        return (
            <div className="loading-container">
              <div className="spinner"></div>
            </div>
        );
    }

    if (!user) {
        navigate('/');
        return null;
    }
    
    return (
        <div className='confirmation'>
            {stars.map(star => (
                <Star key={star.id} x={star.x} y={star.y} />
            ))}
            <h1>Payment Successful!</h1>
            <p>Thank you for signing up with Tekt! Start your journey below:</p>
            <button onClick={handleContinue}>Continue to Dashboard</button>
        </div>
    );
}

export default Paymentsuccess;
