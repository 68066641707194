import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import firebase from 'firebase/app';
import Header from './Header';
import './AboutUs.css';
import LogoTekt from './images/footer/tekt_ai.png';
import InstagramIcon from './images/footer/insta-icon.png';
import TwitterIcon from './images/footer/twitter-icon.png';
import LinkdinIcon from './images/footer/linkdin-icon.png';
import TiktokIcon from './images/footer/tiktok-icon.png';


function AboutUs() {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    
    useEffect(() => {
        const unregisterAuthObserver = firebase.auth().onAuthStateChanged(
            (user) => setIsAuthenticated(!!user)
        );
        return () => unregisterAuthObserver();
    }, []);

    return (
        <div className="about-us-container">
            <Header isAuthenticated={isAuthenticated} />  {/* Pass isAuthenticated prop to Header */}
            <section className="about-us-content">
                <h1>Welcome to Tekt Ai 🎉</h1>
                <h5>Ever looked at a space and thought, "I'd design this differently?" Well, Now's your chance!   Dive into our digital studio where Ai magic meets the world of design and your architectural visions. </h5>

                <h3>Our Mission: Designing Your Reality</h3>
                <h2>At tekt.ai, we're all about giving you the power to shape your surroundings. We dream big of cities and spaces that are molded by the people who use them. Architecture and design are not just for the experts; they are mediums of expression for all. We envision a world where everyone has the agency to influence their surroundings. With our nifty AI, you can design, tweak, and reimagine your spaces, all in real-time.</h2>

                <h3>From Skylines to Your Living Room</h3>
                <h2>Spaces tell stories. And we believe everyone should be the author of their own story. Whether you're a seasoned architect or someone who just wants their room to reflect their vibe, tekt.ai is your playground.</h2>

                <h3>Get in Touch</h3>
                <h2>Your feedback and insights drive our innovation. Don't hesitate to drop us a line at info@tekt.ai, and let's keep the conversation going.</h2>

                <h3>Stay Updated</h3>
                <h2>For insights, updates, and some behind-the-scenes fun, follow us on our socials.</h2>

                <h3>Quick Heads-up</h3>
                <h2>By exploring tekt.ai, you agree to be bound by our<Link className="Policy" to="/Policy">Terms of Service and Privacy Policy</Link>
. We value your trust and promise a secure experience.</h2>

                <h2>Thank you for being a part of our design revolution! Ready to craft some awesomeness? Let's roll 🚀</h2>

                <h2>Tekt.ai. Architekt Your Own Future.</h2>
            </section>
            
            <footer className="footer-aboutus">
                <div className="logo-footer">
                    <img src={LogoTekt} className="logo-footer" alt="Tekt Ai Logo" />
                </div>
                <div className="rights">
                    <p className="footer-sentence">Copyright © 2023 Tekt Ai. All Rights Reserved.</p>
                </div>
                <div className="socials">
                    <a href="https://instagram.com/tekt.ai" className="social-icon" target="_blank" rel="noopener noreferrer"><img src={InstagramIcon} alt="Instagram" width="20" height="20" /></a>
                    <a href="https://twitter.com/tekt_ai" className="social-icon" target="_blank" rel="noopener noreferrer"><img src={TwitterIcon} alt="Twitter" width="20" height="20" /></a>
                    <a href="https://www.linkedin.com/company/tekt-ai/" className="social-icon" target="_blank" rel="noopener noreferrer"><img src={LinkdinIcon} alt="Linkdin" width="20" height="20" /></a>
                    <a href="https://www.tiktok.com/company/tekt.ai/" className="social-icon" target="_blank" rel="noopener noreferrer"><img src={TiktokIcon} alt="Tiktok" width="20" height="20" /></a>
                </div>
            </footer>
        </div>
    );
}

export default AboutUs;
