import React, { useState } from 'react';
import axios from 'axios';

const ImageAnalyzer = () => {
    const [selectedImage, setSelectedImage] = useState(null);
    const [analysisResults, setAnalysisResults] = useState(null);

    const onImageChange = (event) => {
        setSelectedImage(event.target.files[0]);
        setAnalysisResults(null); // Reset previous results
    };

    const handleAnalyzeImage = async () => {
        if (!selectedImage) {
            alert('Please select an image first.');
            return;
        }

        const formData = new FormData();
        formData.append('image', selectedImage);

        try {
            const response = await axios.post('https://tekt-shop.replit.app/analyze-image', formData, {
                headers: { 'Content-Type': 'multipart/form-data' }
            });
            setAnalysisResults(response.data);
        } catch (error) {
            console.error('Error during image analysis:', error);
            alert('Failed to analyze image.');
        }
    };

    return (
        <div>
            <h1>Image Analyzer</h1>
            <input type="file" onChange={onImageChange} accept="image/*" />
            <button onClick={handleAnalyzeImage}>Analyze Image</button>
            {analysisResults && (
                <div>
                    <h2>Analysis Results:</h2>
                    <pre>{JSON.stringify(analysisResults, null, 2)}</pre>
                </div>
            )}
        </div>
    );
};

export default ImageAnalyzer;
