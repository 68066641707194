import React from 'react';
import './Result.css';  

const Result = ({ imageSrc, onClose }) => {
  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <button className="close-modal-btn" onClick={onClose}>X</button>
        {imageSrc && <img src={imageSrc} alt="Enlarged Interior" className="enlarged-image" />}
      </div>
    </div>
  );
}

export default Result;
