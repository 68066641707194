import React, { useState } from 'react';

const SketchGenerator = () => {
    const [prompt, setPrompt] = useState('');
    const [selectedFile, setSelectedFile] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [generatedImage, setGeneratedImage] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);

    const handlePromptChange = (event) => {
        setPrompt(event.target.value);
    };

    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setErrorMessage(null); // Reset error message at the start
        if (!selectedFile || !prompt) {
            alert('Please enter a prompt and select a file.');
            return;
        }
    
        const formData = new FormData();
        formData.append('sketch', selectedFile);
        formData.append('prompt', prompt);
    
        setIsLoading(true);
        try {
            const response = await fetch('https://tekt-sketch.replit.app/generate-image', {
                method: 'POST',
                body: formData,
            });
    
            if (!response.ok) {
                // Handle non-JSON responses
                if (response.headers.get("content-type").includes("application/json")) {
                    const errorData = await response.json();
                    throw new Error(errorData.error || `HTTP error! Status: ${response.status}`);
                } else {
                    const errorText = await response.text();
                    throw new Error(`Non-JSON error response: ${errorText}`);
                }
            }
    
    
            const imageBlob = await response.blob();
            setGeneratedImage(URL.createObjectURL(imageBlob));
        } catch (error) {
            console.error('Failed to generate image:', error);
            setErrorMessage('Failed to generate image.');
        } finally {
            setIsLoading(false);
        }
    };
    

    return (
        <div>
            <h1>Sketch to Image Generator</h1>
            <form onSubmit={handleSubmit}>
                <div>
                    <label>Prompt:</label>
                    <input type="text" value={prompt} onChange={handlePromptChange} />
                </div>
                <div>
                    <label>Upload Sketch:</label>
                    <input type="file" accept="image/*" onChange={handleFileChange} />
                </div>
                <button type="submit" disabled={isLoading}>Generate</button>
            </form>
            {generatedImage && <img src={generatedImage} alt="Generated" />}
            {errorMessage && <div className="error-message">{errorMessage}</div>}
        </div>
    );
};

export default SketchGenerator;
