import React, { useState, useRef, useEffect } from 'react';
import ReactModal from 'react-modal';
import ReactCrop from 'react-image-crop';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'react-image-crop/dist/ReactCrop.css';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import './DashboardChatbot.css';
import './DashboardInteriors.css';
import backgroundImage from './images/background/tekt.png'; 
import { auth } from '../firebase';
import assistantIcon from './images/dashboard/Assistant.png';
import profileIcon from './images/dashboard/Profile.png';
import historyIcon from './images/dashboard/History.png';
import customizeIcon from './images/dashboard/customize-icon.png';
import generateIcon from './images/dashboard/magic-icon.png';
import uploadIcon from './images/dashboard/upload-icon.png';
import imageIcon from './images/dashboard/image-icon.png';
import downloadIcon from './images/dashboard/download-icon.png';
import magnifierIcon from './images/dashboard/magnifier.png';
import infoIcon from './images/dashboard/info-icon.png';
import interiorIcon from './images/dashboard/interior-icon.png';
import exteriorIcon from './images/dashboard/exterior-icon.png';
import Result from './Result';
import secondSidebarIcon from './images/dashboard/image-icon.png';
import menuOpenIcon from './images/dashboard/menu-Open-icon.png';
import menuCloseIcon from './images/dashboard/menu-Close-icon.png';
import axios from 'axios';
import CameraIcon from './images/dashboard/camera.png';
import './LoadingSpinner.css';


const DashboardInteriors = () => {

  const navigate = useNavigate();
  const db = firebase.firestore();
  const [isUserPremium, setIsUserPremium] = useState(null);

  useEffect(() => {
      const checkUserStatus = async () => {
          const user = firebase.auth().currentUser;
          if (user) {
              const doc = await db.collection('users').doc(user.uid).get();
              if (doc.exists) {
                  setIsUserPremium(doc.data().paymentStatus === "completed");
              }
          } else {
              alert('User not authenticated.');
              navigate('/login');
          }
      };

      checkUserStatus();
  }, [db, navigate]);

  useEffect(() => {
      if (isUserPremium === false) {
          alert('You need to have a premium subscription to access this page.');
          navigate('/UpgradePremium');
      }
  }, [isUserPremium, navigate]);
  
  
  
  const [chatMessages, setChatMessages] = useState([]);
  const location = useLocation(); // Use location
  const [placeholderText, setPlaceholderText] = useState("Enter a prompt for instant Architectural Response...");
  const chatInputRef = useRef(null);
  const chatBoxRef = useRef(null);
  const isClient = typeof window === 'object';
  const [isSecondSidebarVisible, setSecondSidebarVisible] = useState(true);
  const [isSidebarVisible, setSidebarVisible] = useState(isClient ? window.innerWidth > 798 : false);
  const [isBotTyping, setIsBotTyping] = useState(false);
  const [loadingStates, setLoadingStates] = useState({});
  const [startSchedule, setStartSchedule] = useState(null);
  const [seed, setSeed] = useState(null);
  const [steps, setSteps] = useState(null);
  const [cfgScale, setCfgScale] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [uploadedImageURL, setUploadedImageURL] = useState(null);
  const [prompt, setPrompt] = useState('');
  const [responseMessage, setResponseMessage] = useState(null);
  const [imageRef, setImageRef] = useState(null);
  const [isLoadingImage, setIsLoadingImage] = useState(false);
  const [isResultModalOpen, setIsResultModalOpen] = useState(false);
  const [targetedImageSrc, setTargetedImageSrc] = useState(null);

  const handleMagnifierClick = (imageSrc) => {
    if(imageSrc) {
      setTargetedImageSrc(`data:image/png;base64,${imageSrc}`);
      setIsResultModalOpen(true);
    } else {
        console.error("No image available to magnify.");
    }
  };
  const downloadImage = (dataUrl, filename) => {
    const downloadLink = document.createElement('a');
    downloadLink.href = dataUrl;
    downloadLink.download = filename;
  
    // Append the anchor to the document body (this is required for Firefox)
    document.body.appendChild(downloadLink);
  
    // Programmatically trigger a click
    downloadLink.click();
  
    // Clean up by removing the anchor from the body
    document.body.removeChild(downloadLink);
  };
  const handleDownloadClick = (imageSrc) => {
      downloadImage(`data:image/png;base64,${imageSrc}`, 'tekt_interior_render.png');
  };

  
  

  const onImageChange = (event) => {
    const file = event.target.files[0];
    setSelectedImage(file);
  
    const img = new Image();
    img.src = URL.createObjectURL(file);
    img.onload = () => {
      setUploadedImageURL(img.src);
      // Add the uploaded image to chatMessages with a unique identifier
      setChatMessages(prev => [...prev, { 
          type: 'user', 
          content: img.src,
          timestamp: new Date().getTime()  // unique identifier based on current time
      }]);

      // Reset the file input's value
      event.target.value = null;
    };
  };
  
  
  const styles = [
    { label: 'Modern', startSchedule: 0.45, seed: 0, steps: 50, cfgScale: 9.5 },
    { label: 'MidCentury', startSchedule: 0.45, seed: 0, steps: 50, cfgScale: 10.5 },
    { label: 'Classic', startSchedule: 0.45, seed: 0, steps: 50, cfgScale: 10.5 },
    { label: 'Rustic', startSchedule: 0.35, seed: 0, steps: 50, cfgScale: 10.5 },
    { label: 'Scandinavian', startSchedule: 0.4, seed: 0, steps: 50, cfgScale: 10.5 },
    { label: 'Minimal', startSchedule: 0.45, seed: 0, steps: 50, cfgScale: 10.5 },
    { label: 'Industrial', startSchedule: 0.3, seed: 0, steps: 50, cfgScale: 9.7 },
    { label: 'Cyberpunk', startSchedule: 0.45, seed: 0, steps: 50, cfgScale: 13.5 },
    { label: 'Farmhouse', startSchedule: 0.35, seed: 0, steps: 50, cfgScale: 9.3 },
    { label: 'Bohemian', startSchedule: 0.35, seed: 0, steps: 50, cfgScale: 9.5 },
  ];
  const roomTypes = ['Foyer','Living Room', 'Bedroom', 'Powder Room', 'Kitchen', 'Bathroom', 'Dining Room', 'Office', 'Den', 'Garage'];
  const aesthetics = ['Bright', 'Dark', 'Warm', 'Glam', 'Vintage', 'Airy', 'Edgy','Gotham','Cyberpunk'];
  const houseTypes = ['Residential','Apartment', 'Suburban','Hometown','Commercial'];

  const [selectedStyle, setSelectedStyle] = useState(styles[0]);
  const [selectedRoomType, setSelectedRoomType] = useState(roomTypes[0]);
  const [selectedAesthetic, setSelectedAesthetic] = useState(aesthetics[0]);
  const [selectedHouseType, setSelectedHouseType] = useState(houseTypes[0]);


  const onPromptChange = (event) => {
    setPrompt(event.target.value);
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    if (chatBoxRef.current) {
      chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
    }

    const currentLabelContent = `${selectedRoomType} in ${selectedHouseType} with ${selectedStyle.label} and ${selectedAesthetic}`;

    setChatMessages(prev => [...prev, { type: 'bot', content: null, label: currentLabelContent, loading: true, style: selectedStyle.label, roomType: selectedRoomType, houseType: selectedHouseType, aesthetic: selectedAesthetic }]);

    try {
        const formData = new FormData();
        formData.append('image', selectedImage);
        formData.append('startSchedule', selectedStyle.startSchedule);
        formData.append('seed', selectedStyle.seed);
        formData.append('steps', selectedStyle.steps);
        formData.append('cfgScale', selectedStyle.cfgScale);
        formData.append('roomtype', selectedRoomType);
        formData.append('aesthetic', selectedAesthetic);
        formData.append('housetype', selectedHouseType);
        formData.append('style', selectedStyle.label);

        const response = await axios.post('https://tekt-image-interiors.replit.app/image_interiors/', formData);
        setResponseMessage(response.data.image);

        setChatMessages(prev => {
            const updatedMessages = [...prev];
            updatedMessages[updatedMessages.length - 1].content = response.data.image;
            updatedMessages[updatedMessages.length - 1].loading = false;
            return updatedMessages;
        });

    } catch (error) {
        console.error(error.response ? error.response.data : error);
        setResponseMessage('Upload failed: ' + error.message);

        // Update the loading state to false even on error
        setChatMessages(prev => {
            const updatedMessages = [...prev];
            updatedMessages[updatedMessages.length - 1].loading = false;
            return updatedMessages;
        });
    }
};


  
  
  // ... rest of the code remains unchanged
  
  const setVH = () => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  };

  const [isLaptopView, setIsLaptopView] = useState(window.innerWidth > 768); // Determining the view

  useEffect(() => {
    const handleResize = () => {
      setIsLaptopView(window.innerWidth > 768);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    return () => {
      // Release the object URL to free up memory
      if (uploadedImageURL) {
        URL.revokeObjectURL(uploadedImageURL);
      }
    };
  }, [uploadedImageURL]);  

  const setPlaceholderBasedOnWidth = () => {
    if (window.innerWidth <= 400) {
      setPlaceholderText("Enter Architectural Prompt.");
    } else {
      setPlaceholderText("Enter a prompt for instant Architectural Response...");
    }
  };

  useEffect(() => {
    setVH();
    window.addEventListener('resize', setVH);

    setPlaceholderBasedOnWidth();

    if (chatBoxRef.current) {
      chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
    }

    return () => {
      window.removeEventListener('resize', setVH);
    };
  }, [chatMessages]);

  useEffect(() => {
    window.addEventListener('resize', setPlaceholderBasedOnWidth);

    return () => {
      window.removeEventListener('resize', setPlaceholderBasedOnWidth);
    };
  }, []);

  useEffect(() => {
    if (isClient) {
      const handleResize = () => {
        setSidebarVisible(window.innerWidth > 768);
      };
      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }
  }, [isClient]);

  useEffect(() => {
    if (chatBoxRef.current) {
      chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
    }
  }, [chatMessages]);

  

  const handleSignOut = async () => {
    try {
      await auth.signOut();
      // Remove the alert so it directly navigates to the homepage
      navigate('/');  // redirect to homepage after successful sign-out
    } catch (error) {
      alert(error.message);
    }
  };
  const labelContent = `${selectedRoomType} in ${selectedHouseType} with ${selectedStyle.label} and ${selectedAesthetic}`;
  if (isUserPremium === null) {
    return <div className="loading-container">
    <div className="spinner"></div>
  </div>;  // Loading state while we verify user's premium status
}


  return (
    <>
      <div className="box-container">
        <button 
          className="sidebar-icon" 
          onClick={() => setSidebarVisible(!isSidebarVisible)}
        >       
        </button>

        <div id="side-bar" style={{ display: isSidebarVisible ? 'block' : 'none' }}>
          <ul>
          <li className={location.pathname === '/DashboardChatbot' ? 'active-link' : ''}>
              <Link to="/DashboardChatbot"><p className="sidebarlinks">💬</p> Architekt</Link>
          </li>
          <li className={location.pathname === '/DashboardImageGenerator' ? 'active-link' : ''}>
              <Link to="/DashboardImageGenerator"><p className="sidebarlinks">🏞️</p> Visuals</Link>
          </li>
          <li className={location.pathname === '/DashboardInteriors' ? 'active-link' : ''}>
              <Link to="/DashboardInteriors"><p className="sidebarlinks">🛋️</p> Interiors</Link>
          </li>
          <li className={location.pathname === '/DashboardExteriors' ? 'active-link' : ''}>
                <Link to="/DashboardExteriors"><p className="sidebarlinks">🏡</p> Exteriors</Link>
          </li>
          <li className={location.pathname === '/#' ? 'active-link' : ''}>
              <Link to="/"><p className="sidebarlinks">📍</p>Home</Link>
          </li>

          <li>
            <Link 
              to="/" 
              onClick={(e) => {
                e.preventDefault(); 
                handleSignOut();
              }}
            >
              <p className="sidebarlinks">🚪</p>Log out</Link>
          </li>
        </ul>
        </div>
  <div className="rightside">
  <form onSubmit={onSubmit} className='input-form'>
      {/* Step 1: Upload Image */}
      <div className="ribon-container">
        <div className="title1"> Upload an image of your interior</div>
        <div className="title2"> For best results, make sure to upload a clear photo in a 90° straight angle.</div>
        <div className="ribbon-overlay">
          <img className="step-icon" src={uploadIcon} alt="Upload Icon" />
          <span className="ribon-text">
            <strong>Step 1:Upload Image</strong> 
          </span>
        </div>
      </div>

      {/* Camera Capture Input */}
      <input 
                      type="file" 
                      id="camera-capture"
                      accept="image/*"
                      onChange={onImageChange} 
                      style={{ display: 'none' }}  // This hides the actual input element
                    />
                  {/* Existing Upload Input */}
                  <label htmlFor="file-upload" className="file_uploader_placeholder">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                      <path d="M512 109.8v292.5c0 16.7-3.9 33.1-11.4 48l-61.8-67.8-51-56c-13.8-15-34.4-25.8-51.7-18.9-18.7 10.8-36.3 23.2-52.6 37.3l-43.5-48c-21.9-24.2-59.2-26-83.4-4.1-1.4 1.3-2.8 2.7-4.1 4.1L73.1 384l-61.3 67.8C4 436.5-.1 419.5 0 402.2V109.8C0 49.1 49.1 0 109.8 0h292.5C462.9 0 512 49.1 512 109.8z" fill="#181818"></path>
                      <path d="M500.6 450.6C487 478.6 462 499.5 432 508L320 384l-35.7-39.2c16-14 33.3-26.5 51.7-37.3 17.4-6.9 38.1 3.8 51.7 18.9l51 56.3 61.9 67.9z" fill="#333"></path>
                      <path d="M432.2 508c-9.6 2.6-19.5 4-29.4 4H110c-41.4 0-79.3-23.2-97.9-60.2L73.3 384l79.2-87c21.9-24.2 59.2-26 83.4-4.1 1.4 1.3 2.8 2.7 4.1 4.1l43.5 48 35.7 39 113 124z" fill="#555"></path>
                      <circle cx="347.4" cy="164.6" r="36.6" fill="#777"></circle>
                  </svg>
                    <span>Click to upload your image.</span>
                    <input 
                        type="file" 
                        id="file-upload"
                        onChange={onImageChange} 
                        accept="image/png, image/jpeg" 
                        className="unique-file-input"
                        style={{ display: 'none' }}  // This hides the actual input element
                    />
                </label>
      {/* Step 2: Customize */}
      <div className="ribon-container">
        <div className="ribbon-overlay">
          <img className="step-icon" src={customizeIcon} alt="Customize Icon" />
          <span className="ribon-text">
            <strong>Step 2:Customize</strong> 
          </span>
        </div>
      </div>

      <div className="customize-section">
        {/* Room Type */}
        <div className="customize-box">
          <label className="customize-label">
            Room Type
          </label>
          <select
                          value={selectedRoomType}
                          onChange={(e) => setSelectedRoomType(e.target.value)}
                          className="customize-select"
                      >
                          <option value="Foyer">Foyer</option>
                          <option value="Living Room">Living Room</option>
                          <option value="Dining Room">Dining Room</option>
                          <option value="Kitchen">Kitchen</option>
                          <option value="Poweder Room">Powder Room</option>
                          <option value="Bedroom">Bedroom</option>
                          <option value="Bathroom">Bathroom</option>
                          <option value="Office">Office</option>
                          <option value="Garage">Garage</option>
                          <option value="Barbershop">Barbershop</option>
                          <option value="Den">Den</option>
                          {/* ... other room types ... */}
                      </select>
        {/* Design Style */}
          <label className="customize-label">Design Style</label>
          {/* Style Dropdown */}
          <select
                          value={selectedStyle.label} // value is now selectedStyle.label, as selectedStyle is an object
                          onChange={(e) => {
                          // Find the style object that matches the selected label
                          const style = styles.find(s => s.label === e.target.value);
                          setSelectedStyle(style);
                          }}
                          className="customize-select"
                      >
                          {styles.map((style, index) => (
                          <option key={index} value={style.label}>{style.label}</option>
                          ))}
                      </select>      
          {/* Aesthetic Dropdown */}
          <label className="customize-label">Aesthetic</label>
          <select
                          value={selectedAesthetic}
                          onChange={(e) => setSelectedAesthetic(e.target.value)}
                          className="customize-select"
                      >
                          <option value="Bright">Bright</option>
                          <option value="Warm">Warm</option>
                          <option value="Dark">Dark</option>
                          <option value="Gotham">Gotham</option>
                          <option value="Glam">Glam</option>
                          <option value="Vintage">Vintage</option>
                          <option value="Airy">Airy</option>
                          <option value="Edgy">Edgy</option>
                          <option value="Calm">Calm</option>
                          <option value="Vibrant">Vibrant</option>
                          {/* ... other aesthetics ... */}
                      </select>  
          <label className="customize-label">Property Type</label>
          <select
                          value={selectedHouseType}
                          onChange={(e) => setSelectedHouseType(e.target.value)}
                          className="customize-select"
                      >
                          <option value="Residential">Residential</option>
                          <option value="Apartment">Apartment</option>
                          <option value="Suburban">Suburban</option>
                          <option value="Hometown">Hometown</option>
                          <option value="Commercial">Commercial</option>
                          {/* ... other house types ... */}
                      </select>  
        </div>
        {/* ... other customization boxes ... */}
      </div>

      {/* Step 3: Generate */}
      <div className="ribon-container">
        <div className="ribbon-overlay">
          <img className="step-icon" src={generateIcon} alt="Generate Icon" />
          <span className="ribon-text">
            <strong>Step 3:Generate</strong> 
          </span>
        </div>
      </div>

      <button type="submit" className="unique-submit-button">Generate</button>
    </form>
    <div id="interior-box" ref={chatBoxRef}>
    <div className="grid">
        {chatMessages.map((message, index) => {
            if (message.type === 'user') {
                let botMessage = chatMessages[index + 1]; // Assuming bot message always follows user message
                return (
                    <div className="row-container" key={index}>
                        <div className="image-container">
                            <img src={message.content} alt="" className="user-input" />
                            <div className="floating_infos">
                                Original Render
                            </div>
                        </div>
                        {botMessage && botMessage.type === 'bot' ? (
                            <div className="image-container">
                                {botMessage.loading ? (
                                    <div className="loading-animation">
                                        <span className="loading-icon"><span></span></span>
                                    </div>
                                ) : (
                                    <>
                                        <img src={`data:image/png;base64,${botMessage.content}`} alt="Generated Interior" className="bot-interior-image" />
                                        <div className="image-icons">
                                        <button className="view-image-btn" onClick={() => handleMagnifierClick(botMessage.content)}>
                                          <img className="magnifier-icon" src={magnifierIcon} alt="Magnifier Icon" />
                                      </button>
                                      <button className="dwn-image-btn" onClick={() => handleDownloadClick(botMessage.content)}>
                                          <img className="download-icon" src={downloadIcon} alt="Download Icon" />
                                      </button>
                                        </div>
                                        <div className="floating_infos">
                                         {botMessage.style} {botMessage.roomType} {botMessage.houseType} {botMessage.aesthetic}
                                        </div>
                                        
                                    </>
                                  
                                )}
                                                        {isResultModalOpen && (
                                          <Result
                                            imageSrc={targetedImageSrc}
                                            onClose={() => setIsResultModalOpen(false)}
                                          />
                                        )}
                            </div>
                        ) : null}
                    </div>
                );
            }
            return null;
        })}
    </div>
</div>

</div>

  </div>


    </>
  );
};

export default DashboardInteriors;