import React, { useState, useEffect } from 'react';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import Modal from 'react-modal';
import './EditProfile.css';
import backIcon from './images/dashboard/go-back-icon.png';
import { useNavigate } from 'react-router-dom';

Modal.setAppElement('#root');

function EditProfile({ modalIsOpen, closeModal, openProfileModal }) {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const handleBackButtonClick = () => {
    closeModal();
    openProfileModal();
  };

  const handleDeleteAccountClick = () => {
    setIsDeleteModalOpen(true);
  };

  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false);
  };

  useEffect(() => {
    const user = firebase.auth().currentUser;
    if (user) {
      const userRef = firebase.firestore().collection('users').doc(user.uid);
      userRef.get().then(doc => {
        if (doc.exists) {
          const data = doc.data();
          setFirstName(data.firstName || '');
          setLastName(data.lastName || '');
          setEmail(user.email);
        } else {
          console.error("No user document found!");
        }
      }).catch(err => {
        console.error("Error fetching user profile:", err);
      });
    }
  }, [modalIsOpen]);

  const handleNameSubmit = async (e) => {
    e.preventDefault();
    const userRef = firebase.firestore().collection('users').doc(firebase.auth().currentUser.uid);
    try {
      await userRef.set({ firstName, lastName }, { merge: true });
      closeModal();
      alert('Names updated successfully');
    } catch (err) {
      alert(err.message);
    }
  };

  const handlePasswordSubmit = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setError("Passwords do not match!");
      return;
    }
    try {
      await firebase.auth().currentUser.updatePassword(password);
      setPassword('');
      setConfirmPassword('');
      closeModal();
      alert('Password updated successfully');
    } catch (err) {
      alert(err.message);
    }
  };

  return (
    <>
      <Modal 
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        className="modal"
        overlayClassName="overlay"
      >
        <h2>Edit Profile</h2>
        <button className="close-button" onClick={closeModal}>X</button>
        <button className="back-button" onClick={handleBackButtonClick}><img className="go-back-icon" src={backIcon} alt="Icon" /></button>
        
        <form className="edit-profile-form" onSubmit={handleNameSubmit}>
          <input
            name="firstName"
            type="text"
            placeholder="First Name"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            required
          />
          <input
            name="lastName"
            type="text"
            placeholder="Last Name"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            required
          />
          <input 
            name="email"
            type="email"
            placeholder="Email"
            value={email}
            onChange={e => setEmail(e.target.value)}
          />
          <button type="submit">Update names</button>
        </form>

        <form className="edit-profile-form" onSubmit={handlePasswordSubmit}>
          <input
            name="password"
            type="password"
            placeholder="New Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <input
            name="confirmPassword"
            type="password"
            placeholder="Confirm Password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
          <button type="submit">Update password</button>
          <button type="button" className="delete" onClick={handleDeleteAccountClick}>Delete account</button>
        </form>

        {error && <p className="edit-profile-error">{error}</p>}
      </Modal>

      <Modal 
        isOpen={isDeleteModalOpen}
        onRequestClose={closeDeleteModal}
        className="delete-modal"
        overlayClassName="overlay"
      >
        <h2>Account Deletion Request</h2>
        <button className="close-button" onClick={closeDeleteModal}>X</button>
        <p>We're sad to see you go. To proceed with removing your access, kindly reach out to us at<a href="mailto:info@tekt.ai">info@tekt.ai</a> and our team will assist you further. Your feedback is appriciated. Take care & Thank you 💜</p>
      </Modal>
    </>
  );
}

export default EditProfile;
