import React, { useState, useEffect } from 'react';
import firebase from 'firebase/app';
import Header from './Header'; // Import the Header component
import './Faq.css'; // Make sure your CSS path is correct
import LogoTekt from './images/footer/tekt_ai.png';
import InstagramIcon from './images/footer/insta-icon.png';
import TwitterIcon from './images/footer/twitter-icon.png';
import LinkdinIcon from './images/footer/linkdin-icon.png';
import TiktokIcon from './images/footer/tiktok-icon.png';

// FaqItem component
const FaqItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => setIsOpen(!isOpen);

  return (
    <div className="faq-item">
      <button className="faq-question" onClick={toggleOpen} aria-expanded={isOpen}>
        {question}
      </button>
      <div className={`faq-answer ${isOpen ? 'open' : ''}`} aria-hidden={!isOpen}>
        <p>{answer}</p>
      </div>
    </div>
  );
};

// Main Faq component
const Faq = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const unregisterAuthObserver = firebase.auth().onAuthStateChanged(user => {
      setIsAuthenticated(!!user);
    });
    return () => unregisterAuthObserver(); // Make sure we un-register Firebase observers when the component unmounts.
  }, []);

  const faqs = [
    {
      question: 'How can I get started with using Tekt.Ai?',
      answer: 'To start using Tekt.Ai, simply register for an account on our website. Once registered, you have access to our suite of tools, including the Exterior Generator and Image Generator. For the Interior Generator, navigate to the feature on our platform and subscribe for premium access.'
    },
    {
      question: 'How does the Interior Generator work?',
      answer: 'Our Interior Generator allows you to upload a photo of your space and select a style of your choice. Our AI will then redesign your interior in the chosen style with just one click. Note that this is a premium feature available to users with a paid subscription.'
      // ... other FAQs
    },
    {
      question: 'Is the Exterior Generator free to use?',
      answer: 'Yes, the Exterior Generator is free after signing up. You can upload an image of any exterior space and choose from over 50 styles to transform it instantly.'
      // ... other FAQs
    },
    {
      question: 'Does the AI chatbot only answer architecture and design questions?',
      answer: 'Primarily, yes. Our AI chatbot is specifically programmed to assist with architectural and design-related inquiries, providing expert guidance and information in these areas.'
      // ... other FAQs
    },
    {
      question: 'Can I generate any image with the Image Generator?',
      answer: 'Absolutely! With our Image Generator, you provide a written prompt, and our AI will create a detailed visual render based on your description. This feature is free for all registered users.'
      // ... other FAQs
    },
    {
      question: 'Are there any restrictions on the number of images I can generate?',
      answer: 'While most Tekt products are free to signed-up users, to maintain a high level of service and availability for all users, there is a limit on the number of images you can generate per day, ensuring fair usage and server stability. Please check our terms of service for updated information regarding this number.'
    },
    {
      question: 'What styles can I choose from for the Interior and Exterior Generators?',
      answer: 'We offer a diverse range of styles from classical to modern, minimalistic to baroque, and many more. We are continually updating our library with new styles to provide you with the latest trends in design.'
    },
    {
      question: 'How can I sign up for the Interior Generator premium service?',
      answer: 'To subscribe to our premium Interior Generator service, simply sign up for an account on Tekt.Ai, navigate to the premium product on your dashboard, and you will find the option to subscribe and gain a full access. Our subscription process is secure and straightforward.'
    },
    {
      question: 'Is my data safe with Tekt.Ai?',
      answer: 'Protecting your privacy is crucial to us. We encrypt all data and adhere to the highest data protection standards to ensure your information and generated images are secure.'
    },
    {
      question: 'I have feedback on the Ai-generated designs. How can I share it?',
      answer: 'We thrive on user feedback to improve our services. You can share your thoughts and suggestions to support@tekt.ai. We are dedicated to continually improving our offerings based on user input.'
    },
  ];

  return (
    <div className="faq-container">
      <Header isAuthenticated={isAuthenticated} />
      <section className="faq-content">
      {faqs.map((faq, index) => (
        <FaqItem key={index} question={faq.question} answer={faq.answer} />
      ))}
      </section>
      <footer className="footer-aboutus">
                <div className="logo-footer">
                    <img src={LogoTekt} className="logo-footer" alt="Tekt Ai Logo" />
                </div>
                <div className="rights">
                    <p className="footer-sentence">Copyright © 2023 Tekt Ai. All Rights Reserved.</p>
                </div>
                <div className="socials">
                    <a href="https://instagram.com/tekt.ai" className="social-icon" target="_blank" rel="noopener noreferrer"><img src={InstagramIcon} alt="Instagram" width="20" height="20" /></a>
                    <a href="https://twitter.com/tekt_ai" className="social-icon" target="_blank" rel="noopener noreferrer"><img src={TwitterIcon} alt="Twitter" width="20" height="20" /></a>
                    <a href="https://www.linkedin.com/company/tekt-ai/" className="social-icon" target="_blank" rel="noopener noreferrer"><img src={LinkdinIcon} alt="Linkdin" width="20" height="20" /></a>
                    <a href="https://www.tiktok.com/company/tekt.ai/" className="social-icon" target="_blank" rel="noopener noreferrer"><img src={TiktokIcon} alt="Tiktok" width="20" height="20" /></a>
                </div>
        </footer>
    </div>
    
  );
};

export default Faq;
