import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import firebase from 'firebase/app';
import 'firebase/auth';
import * as particlesJS from 'particles.js';
import Header from './Header';
import { useAuth } from '../hooks/useAuth'; 
import './HomePage.css';
import SignUp from './SignUp'; 
import Login from './Login';
import LogoTekt from './images/footer/tekt_ai.png';
import InstagramIcon from './images/footer/insta-icon.png';
import TwitterIcon from './images/footer/twitter-icon.png';
import LinkdinIcon from './images/footer/linkdin-icon.png';
import TiktokIcon from './images/footer/tiktok-icon.png';
import VideoTekt from './images/body/openingvideo.mp4';
import Exterior1 from './images/homepage/tekt-suburban.png';
import Exterior2 from './images/homepage/tekt-vintage.png';
import Exterior3 from './images/homepage/tekt-farmhouse.png';
import Exterior4 from './images/homepage/tekt-traditional.png';
import Exterior5 from './images/homepage/tekt-coastal.png';
import Exterior6 from './images/homepage/tekt-cottage.png';
import Exterior7 from './images/homepage/tekt-modern.png';



const images = [
    { src: Exterior1 },
    { src: Exterior2 },
    { src: Exterior3 },
    { src: Exterior4 },
    { src: Exterior5 },
    { src: Exterior6 },
    { src: Exterior7 },
    // Add more images as needed
];
function HomePage() {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const navigate = useNavigate();
    const {
        user,
        signUp,
        signIn,
        signout,
        signupModalIsOpen,
        openSignupModal,
        closeSignupModal,
        loginModalIsOpen,
        openLoginModal,
        closeLoginModal,
    } = useAuth();
    const [currentImageIndex, setCurrentImageIndex] = useState(0);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentImageIndex(prevIndex => (prevIndex + 1) % images.length);
        }, 3500); // Change image every 3 seconds

        return () => clearInterval(intervalId);
    }, []);

    const currentImage = images[currentImageIndex];

    useEffect(() => {
        document.body.style.backgroundColor = currentImage.bgColor;
    }, [currentImage]);

    useEffect(() => {
        const unregisterAuthObserver = firebase.auth().onAuthStateChanged(
            (user) => setIsAuthenticated(!!user)
        );

        return () => unregisterAuthObserver();
    }, []);

    const handleAskArchieClick = (event) => {
        event.preventDefault();
        if (isAuthenticated) {
            navigate('/Launcher');
        } else {
            openSignupModal();
        }
    };

    const containerRef = useRef(null);
    const particlesJS = require('particles.js');
    const [isHovered, setIsHovered] = useState(false);

    useEffect(() => {
        if (window.particlesJS) {
           window.particlesJS.load('particles-js', '/particles.json', function() {
              console.log('particles.js loaded');
           });
        }
     }, []);
     const goToNextImage = () => {
        setCurrentImageIndex(prevIndex => (prevIndex + 1) % images.length);
    };

    const goToPrevImage = () => {
        setCurrentImageIndex(prevIndex => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
    };
    
       
    const [texts, setTexts] = useState([
        "How to become an Architect ?",
        "Help me pick flooring for my new patio .",
        "Tell me about Brutalist architecture .",
        "Generate an image of the pyramids on mars .",
        "Who was Zaha Hadid ?",
        "Give me ideas for a vibrant office space .",
        "Show me my bedroom in bohemian style .",
        "Make my kitchen look bright & modern .",
        "When were lofts first popularized ?",
        "How to draw a two-point perspective ?"
    ]);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [displayedText, setDisplayedText] = useState('');
    const typeInterval = useRef(null);
    
    // 1. Add the setVH function definition
    const setVH = () => {
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    };

    // 2. Register the window event listeners inside a useEffect hook
    useEffect(() => {
        setVH();
        window.addEventListener('resize', setVH);
        window.addEventListener('orientationchange', setVH);
        
        // 3. Clean up (unregister) the event listeners when the component unmounts
        return () => {
            window.removeEventListener('resize', setVH);
            window.removeEventListener('orientationchange', setVH);
        };
    }, []); 

    useEffect(() => {
        const currentText = texts[currentIndex];

        if (!currentText) {
            return;
        }

        let textIndex = 0;

        const typeCharacter = () => {
            setDisplayedText((prevText) => currentText.substring(0, textIndex + 1));
            textIndex++;

            if (textIndex >= currentText.length) {
                clearInterval(typeInterval.current);
                setTimeout(() => {
                    setCurrentIndex((prevIndex) => (prevIndex + 1) % texts.length);
                    setDisplayedText(''); // Reset the displayed text
                }, 2500); // Duration to display the full text before moving to the next
            }
        };

        typeInterval.current = setInterval(typeCharacter, 100); // Adjust 100ms to your preferred typing speed

        return () => clearInterval(typeInterval.current);
    }, [currentIndex, texts]);

    return (
        <div className="Homepage" ref={containerRef}>
            {/* Add a div to contain particles */}
            <div id="particles-js"></div>

            <Header isAuthenticated={isAuthenticated} />
            <SignUp
                modalIsOpen={signupModalIsOpen}
                closeModal={closeSignupModal}
                openLoginModal={openLoginModal}
                closeLoginModal={closeLoginModal}
            />
            <section className="hero">
                <div className="hero-text">
                    <h1 className="title">Design your <br/> home with <br/> AI Architekt</h1>
                    <section className='Dashboard-button'>
                    </section>
                    <div className="description-text">
                        <h4>
                            Explore, generate, customize, and manifest
                            <br/>
                            your dream space in less than 5 minutes. 
                        </h4>
                        <button 
                            className={isAuthenticated ? "design-kit-button" : "cta-button"} 
                            onClick={handleAskArchieClick}
                        > 
                            {isAuthenticated ? "Design Studio" : "Continue for FREE"}
                        </button>
                        <h4>
                        <br/>
                        No design experience required! 
                        </h4>
                    </div>
                </div>
                <div 
                    className="image-slider" 
                    onMouseEnter={() => setIsHovered(true)} 
                    onMouseLeave={() => setIsHovered(false)}
                >
                    <img src={currentImage.src} alt="Slideshow" />
                    {isHovered && (
                        <>
                            <button className="slider-button prev" onClick={goToPrevImage} aria-label="Previous style">
                                {/* SVG for the previous button (not flipped) */}
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="white" stroke-linecap="round" stroke-linejoin="round"><polyline points="15 18 9 12 15 6"></polyline></svg>
                            </button>
                            <button className="slider-button next" onClick={goToNextImage} aria-label="Next style">
                                {/* SVG for the next button (flipped) */}
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="white" stroke-linecap="round" stroke-linejoin="round" transform="scale(-1, 1)"><polyline points="15 18 9 12 15 6"></polyline></svg>
                            </button>
                        </>
                    )}
                </div>
                </section>
            <footer className="footer">
                <div className="logo-footer">
                    <img src={LogoTekt} className="logo-footer" alt="Tekt Ai Logo" />
                </div>
                <div className="rights">
                    <p className="footer-sentence">Copyright © 2023 Tekt Ai. All Rights Reserved.</p>
                </div>
                <div className="socials">
                    <a href="https://instagram.com/tekt.ai" className="social-icon" target="_blank" rel="noopener noreferrer">
                        <img src={InstagramIcon} alt="Instagram" width="20" height="20" />
                    </a>
                    <a href="https://twitter.com/tekt_ai" className="social-icon" target="_blank" rel="noopener noreferrer">
                        <img src={TwitterIcon} alt="Twitter" width="20" height="20" />
                    </a>
                    <a href="https://www.linkedin.com/company/tekt-ai/" className="social-icon" target="_blank" rel="noopener noreferrer">
                        <img src={LinkdinIcon} alt="Linkdin" width="20" height="20" />
                    </a>
                    <a href="https://www.tiktok.com/company/tekt.ai/" className="social-icon" target="_blank" rel="noopener noreferrer">
                        <img src={TiktokIcon} alt="Tiktok" width="20" height="20" />
                    </a>
                </div>
            </footer>
            <script src="particles.js"></script>

        </div>
    );
}

export default HomePage;