import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth } from '../firebase';
import logo from './images/header/tekt_logo.png';
import './Launcher.css';
import { Link } from 'react-router-dom';


const Launcher = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setUser(user);
      } else {
        navigate('/login');
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  const handleNavigation = (path) => {
    if (user) {
      navigate(path);
    } else {
      alert('You need to be authenticated to access this page.');
      navigate('/login');
    }
  };
  const ArrowIcon = () => (
    <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" height="20" width="20" xmlns="http://www.w3.org/2000/svg">
      <line x1="5" y1="12" x2="19" y2="12"></line>
      <polyline points="12 5 19 12 12 19"></polyline>
    </svg>
  );

  return (
    <div className="route-container">
      <div className="launcher">
        <div className="launcher-body">
          <div className="launcher-header">
          <Link className="Policy" to="/"> <img className='launcher-logo' src={logo} id="logo" alt="Logo" height="60" /></Link>
          </div>
          <div className="launcher-menu">
            <div className="launcher-item" onClick={() => handleNavigation('/DashboardChatbot')}>
              <div className="launcher-item-header">
                <h1 className="launcher-text">Ai Architekt</h1>
                <ArrowIcon />
              </div>
              <div className="launcher-item-desc">Chat with Architekt for expert design tips and ideas</div>
            </div>
            <div className="launcher-item" onClick={() => handleNavigation('/Interiors')}>
              <div className="launcher-item-header">
                <h1 className="launcher-text">Interiors</h1>
                <ArrowIcon />
              </div>  
              <div className="launcher-item-desc">Personalize your indoor space with tailor-made designs to suit your taste</div>
            </div>
            <div className="launcher-item" onClick={() => handleNavigation('/DashboardExteriors')}>
              <div className="launcher-item-header">
                <h1 className="launcher-text">Exteriors</h1>
                <ArrowIcon />
              </div>
              <div className="launcher-item-desc">Explore and select various stylish exteriors for your dream home</div>
            </div>
            <div className="launcher-item" onClick={() => handleNavigation('/DashboardImageGenerator')}>
              <div className="launcher-item-header">
                <h1 className="launcher-text">Visuals</h1>
                <ArrowIcon />
              </div>
              <div className="launcher-item-desc">Turn your textual description into high quality visual representations</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Launcher;
