import React from 'react';
import firebase from 'firebase/app';
import { useNavigate } from 'react-router-dom';
import './UpgradePremium.css';

function UpgradePremium() {
    const navigate = useNavigate();
    const db = firebase.firestore();
    const goToHomepage = () => {
        navigate('/');
    };

    const redirectToStripe = async () => {
        const user = firebase.auth().currentUser;
        if (user) {
            const doc = await db.collection('users').doc(user.uid).get();
            if (doc.exists) {
                const paymentStatus = doc.data().paymentStatus;
                if (paymentStatus === "completed") {
                    const confirmed = window.confirm('You are already a premium customer. Press OK to access full premium features.');
                    if (confirmed) {
                        navigate('/DashboardInteriors');
                    }
                } else {
                    window.location.href = "https://buy.stripe.com/dR615Jc4I0g8dRmfYZ";
                }
            } else {
                alert('Unable to fetch payment status. Please try again.');
            }
        } else {
            alert('User not authenticated.');
            navigate('/login');
        }
    
    };

    return (
        <div className="upgrade">
            <h2>This Feature is Premium</h2>
            <p>For accessing interior generator, please upgrade your account below</p>
            <button onClick={redirectToStripe}>Upgrade to Premium</button>
            <button onClick={goToHomepage} style={{ marginTop: '40px', background: 'transparent', borderRadius: '30px' , border: '1.5px solid #ffffff' }}>Return to homepage</button>
        </div>
    );
}

export default UpgradePremium;
