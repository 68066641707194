import React, { useState } from 'react';

function ImageForm() {
  const [imageUrl, setImageUrl] = useState('');
  const [prompt, setPrompt] = useState('');
  const [file, setFile] = useState(null);
  const [serverResponse, setServerResponse] = useState(null); // New state variable to store server response

  const handleSubmit = async (event) => {
    event.preventDefault();
    setServerResponse(null); // Reset server response on new submission

    try {
      const formData = new FormData();
      if (file) {
        formData.append('file', file);
      }
      formData.append('prompt', prompt);

      const endpoint = file ? 'https://tekt-vision.tinaghazia.repl.co/analyze-upload' : 'https://tekt-vision.tinaghazia.repl.co/analyze-url';
      const response = await fetch(endpoint, {
        method: 'POST',
        body: file ? formData : JSON.stringify({ image_url: imageUrl, prompt: prompt }),
        headers: file ? {} : { 'Content-Type': 'application/json' },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      setServerResponse(data); // Update state with server response
    } catch (error) {
      console.error('There was a problem with the fetch operation:', error.message);
      setServerResponse({ error: error.message }); // Set error message in state
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          placeholder="Enter Image URL"
          value={imageUrl}
          onChange={(e) => setImageUrl(e.target.value)}
        />
        <input
          type="file"
          onChange={(e) => setFile(e.target.files[0])}
        />
        <input
          type="text"
          placeholder="Enter your prompt"
          value={prompt}
          onChange={(e) => setPrompt(e.target.value)}
        />
        <button type="submit">Analyze</button>
      </form>

      {serverResponse && (
        <div>
          <h3>Server Response:</h3>
          <pre>{JSON.stringify(serverResponse, null, 2)}</pre>
        </div>
      )}
    </div>
  );
}

export default ImageForm;
