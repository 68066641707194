// hooks/useAuth.js
import React from 'react';
import { useState, useEffect, useContext, createContext } from 'react';
import firebase from 'firebase/app';
import 'firebase/auth';

const authContext = createContext();

// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export function ProvideAuth({ children }) {
  const auth = useAuthProvider();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useAuth = () => {
  return useContext(authContext);
};

// Provider hook that creates auth object and handles its state
function useAuthProvider() {
  const [user, setUser] = useState(null);

  // Add these lines
  const [signupModalIsOpen, setSignupModalIsOpen] = useState(false);
  const [loginModalIsOpen, setLoginModalIsOpen] = useState(false); // Add this line

  const openSignupModal = () => {
    setSignupModalIsOpen(true);
  };

  const closeSignupModal = () => {
    setSignupModalIsOpen(false);
  };

  const openLoginModal = () => {
    setLoginModalIsOpen(true); // This now works
  };

  const closeLoginModal = () => {
    setLoginModalIsOpen(false); // This now works
  };

  const signUp = async (email, password) => {
    const response = await firebase.auth().createUserWithEmailAndPassword(email, password);
    setUser(response.user);
  };

  const signIn = async (email, password) => {
    const response = await firebase.auth().signInWithEmailAndPassword(email, password);
    setUser(response.user);
  };

  const signout = () => {
    return firebase.auth().signOut();
  };

  useEffect(() => {
    // Subscribe to user on mount
    const unsubscribe = firebase.auth().onAuthStateChanged(user => {
      if (user) {
        setUser(user);
      } else {
        setUser(null);
      }
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []); // Empty dependency array means this effect runs once on mount and cleanup on unmount

  // Return the user object and auth methods
  // Add signupModalIsOpen, openSignupModal, and closeSignupModal here
  return {
    user,
    signUp,
    signIn,
    signout,
    signupModalIsOpen,
    openSignupModal,
    closeSignupModal,
    loginModalIsOpen,
    openLoginModal,
    closeLoginModal,
  };
}
