import React from 'react';
import Header from './components/Header.js';
import DashboardChatbot from './components/DashboardChatbot';
import DashboardInteriors from './components/DashboardInteriors';
import DashboardExteriors from './components/DashboardExteriors';
import DashboardSketch from './components/DashboardSketch.js';
import SketchGenerator from './components/SketchGenerator.js';
import Interiors from './components/Interiors';
import DashboardImageGenerator from './components/DashboardImageGenerator';
import HomePage from './components/HomePage';
import HomePagee from './components/Homepagee';
import SignUp from './components/SignUp';
import AboutUs from './components/AboutUs';
import Login from './components/Login';
import Launcher from './components/Launcher';
import Policy from './components/Policy';
import Faq from './components/Faq'; 
import Pricing from './components/Pricing';
import Result from './components/Result';
import ImageAnalyzer from './components/ImageAnalyzer';
import ImageForm from './components/ImageForm';
import Premium from './components/UpgradePremium.js';
import ReactModal from 'react-modal';
import EditProfile from './components/EditProfile';
import Paymentsuccess from './components/Paymentsuccess';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { ProvideAuth, useAuth } from './hooks/useAuth';


ReactModal.setAppElement('#root');

function PaymentProtectedRoute({ children }) {
  let auth = useAuth();

  // This assumes that your useAuth hook initializes the user as null and 
  // changes its value only when the auth state is confirmed.
  if (auth.user === null) {
    return (
      <div className="loading-container">
        <div className="spinner"></div>
      </div>
    );
  }

  if (!auth.user) {
    return <Navigate to="/" />;
  }

  return children;
}

function MainApp() {
  let auth = useAuth();
  
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/HomePagee" element={<HomePagee />} />
      <Route path="/Launcher" element={auth.user ? <Launcher /> : <Navigate to="/" />} />
      <Route path="/DashboardChatbot" element={auth.user ? <DashboardChatbot /> : <Navigate to="/" />} />
      <Route path="/DashboardSketch" element={<DashboardSketch />} />
      <Route path="/SketchGenerator" element={<SketchGenerator/>} />
      <Route path="/ImageAnalyzer" element={<ImageAnalyzer/>} />
      <Route path="/DashboardImageGenerator" element={auth.user ? <DashboardImageGenerator /> : <Navigate to="/" />} />
      <Route path="/DashboardInteriors" element={auth.user ? <DashboardInteriors /> : <Navigate to="/" />} />
      <Route path="/DashboardExteriors" element={auth.user ? <DashboardExteriors /> : <Navigate to="/" />} />
      <Route path="/Interiors" element={auth.user ? <Interiors /> : <Navigate to="/" />} />
      <Route path="/signup" element={<SignUp />} />
      <Route path="/Policy" element={<Policy />} />
      <Route path="/login" element={<Login />} />
      <Route path="/Result" element={<Result />} />
      <Route path="/EditProfile" element={auth.user ? <EditProfile /> : <Navigate to="/" />} />
      <Route path="/ImageForm" element={<ImageForm />} />
      <Route path="/AboutUs" element={<AboutUs />} />
      <Route path="/Pricing" element={<Pricing />} />
      <Route path="/Faq" element={<Faq />} />
      <Route path="/UpgradePremium" element={<Premium />} />
      <Route path="/Paymentsuccess" element={
        <PaymentProtectedRoute>
          <Paymentsuccess />
        </PaymentProtectedRoute>
      } />
    </Routes>
  );
}

function App() {
  return (
    <ProvideAuth>
      <Router>
        <MainApp />
      </Router>
    </ProvideAuth>
  );
}

export default App;