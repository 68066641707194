import React, { useState, useRef, useEffect } from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import './DashboardChatbot.css';
import { auth } from '../firebase';
import assistantIcon from './images/dashboard/Assistant.png';
import profileIcon from './images/dashboard/Profile.png';
import historyIcon from './images/dashboard/History.png';
import settingIcon from './images/dashboard/setting-icon.png';
import sendIcon from './images/dashboard/send.svg';
import botIcon from './images/dashboard/bot-icon.png';
import speakerIcon from './images/dashboard/speaker-icon.png';
import imageIcon from './images/dashboard/image-icon.png';
import interiorIcon from './images/dashboard/interior-icon.png';
import exteriorIcon from './images/dashboard/exterior-icon.png';
import infoIcon from './images/dashboard/info-icon.png';
import faqIconLightgray from './images/dashboard/faq-icon.png';
import faqIconBlack from './images/dashboard/faq-dark-icon.png';
import menuOpenIcon from './images/dashboard/menu-Open-icon.png';
import menuCloseIcon from './images/dashboard/menu-Close-icon.png';


const ALL_SUGGESTIONS = [
    'Where is the most visited architectural attraction?',
    'How to become an architect?',
    'What is Brutalist architecture?',
    'Who is Le Corbusier?',
    'How do skyscrapers work?',
    'How to style shelves?',
    'Tell me about modern architecture.',
    'What are the principles of sustainable architecture?',
    'How do skyscrapers work?',
    // ... [Add more if needed]
];

const DashboardSketch = () => {
  const [suggestionQuestions, setSuggestionQuestions] = useState(
    ALL_SUGGESTIONS.slice(0, 5)
  );
  const [selectedFile, setSelectedFile] = useState(null);
  const [chatMessages, setChatMessages] = useState([]);
  const [serverResponse, setServerResponse] = useState(null);
  const [typing, setTyping] = useState(false);
  const location = useLocation();
  const [playingAudioUrl, setPlayingAudioUrl] = useState(null);
  const [placeholderText, setPlaceholderText] = useState("Enter a prompt for instant architectural response...");
  const typeInterval = useRef(null);
  const chatInputRef = useRef(null);
  const chatBoxRef = useRef(null);
  const [audioUrl, setAudioUrl] = useState(null);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const audioRef = useRef(null);
  const [isTextAreaActive, setTextAreaActive] = useState(false);
  const [uploadedFileData, setUploadedFileData] = useState(null);
  const [isFetchingAudio, setIsFetchingAudio] = useState(false);
  


  const setPlaceholderBasedOnWidth = () => {
    if (window.innerWidth <= 700) {
      setPlaceholderText("Enter architectural prompt.");
    } else {
      setPlaceholderText("Enter a prompt for instant architectural response...");
    }
  };

  const handleAudioClick = async (chat) => {
    if (!chat.audioURL) {
      setIsFetchingAudio(true); // Start fetching audio
      const newAudioURL = await fetchAudioForMessage(chat.message);
      setIsFetchingAudio(false); // Audio fetch is complete
      if (newAudioURL) {
        setChatMessages(prevMessages => prevMessages.map(msg => 
          msg === chat ? { ...msg, audioURL: newAudioURL } : msg
        ));
        setPlayingAudioUrl(newAudioURL);
      }
    } else {
      setPlayingAudioUrl(chat.audioURL);
    }
  };
  
  
  
  useEffect(() => {
    if (playingAudioUrl && audioRef.current) {
      audioRef.current.play();
    }
  }, [playingAudioUrl]);
  
  
  const setVH = () => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  };

  useEffect(() => {
    setVH();
    window.addEventListener('resize', setVH);

    setPlaceholderBasedOnWidth();

    if (chatBoxRef.current) {
      chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
    }

    return () => {
      window.removeEventListener('resize', setVH);
    };
  }, [chatMessages]);

  useEffect(() => {
    window.addEventListener('resize', setPlaceholderBasedOnWidth);

    return () => {
      window.removeEventListener('resize', setPlaceholderBasedOnWidth);
    };
  }, []);

  const isClient = typeof window === 'object';

  const [isSidebarVisible, setSidebarVisible] = useState(isClient ? window.innerWidth > 768 : false);

  useEffect(() => {
    if (!isClient) {
      // If not on the client side, just return to avoid accessing window
      return;
    }
    const setVH = () => {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    };
    
    const handleResize = () => {
      setVH();                            // Adjust the VH value
      setPlaceholderBasedOnWidth();       // Adjust the placeholder text
      setSidebarVisible(window.innerWidth > 768);  // Adjust the sidebar visibility
    };
    
    // Call the functions initially to set the initial values
    handleResize();
    
    // Add the event listener for future resize events
    window.addEventListener('resize', handleResize);
    
    return () => {
      // Cleanup: remove the event listener when the component unmounts
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Event handler for textarea changes
  const handleTextareaChange = (e) => {
    const value = e.target.value;
    setTextAreaActive(value.length > 0); // Set active if there's text
    handleInputChange(e); // Your existing input change handler
  };

  // Modify the SVG to change color based on isTextAreaActive state
  const renderSendIcon = () => {
    let color, opacity;
  
    if (isLoading) {
      color = "lightgray";
      opacity = 0.55; // Half opacity when loading
    } else {
      color = isTextAreaActive ? "black" : "lightgray";
      opacity = 1; // Full opacity otherwise
    }
  
    return (
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" className="text-white dark:text-black">
        <path d="M7 11L12 6L17 11M12 18V7" stroke={color} strokeOpacity={opacity} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
      </svg>
    );
  };
  const renderFaqIcon = () => {
    let iconSrc, iconOpacity;
  
    if (isLoading) {
      // When response is being generated
      iconSrc = faqIconLightgray; // Use black icon
      iconOpacity = 0.35; // Reduced opacity
    } else if (isTextAreaActive) {
      // When textarea is active
      iconSrc = faqIconBlack; // Use black icon
      iconOpacity = 1; // Full opacity
    } else {
      // Default state
      iconSrc = faqIconLightgray; // Use light gray icon
      iconOpacity = 1; // Full opacity
    }
  
    return <img className="faq-icon" src={iconSrc} alt="faq icon" style={{ opacity: iconOpacity }} />;
  };  

  const imageInputRef = useRef(null);

  const handleFileDrop = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    if (file && file.type.startsWith('image/')) {
      processFileUpload(file);
    }
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file); // Store the file in state
      setUploadedFileData({
        name: file.name,
        url: URL.createObjectURL(file) // Create a local URL for the file
      });
    }
  };
  

  const processFileUpload = async (file) => {
    const formData = new FormData();
    formData.append('file', file);
  
    try {
      setIsLoading(true);
      const response = await fetch('https://tekt-sketch.replit.app/generate-image', {
        method: 'POST',
        body: formData,
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
  
      const data = await response.json();
      const uploadedImageData = {
        name: file.name,
        url: URL.createObjectURL(file),
      };
    
      // Save the file data in state but don't add a new message here
      setUploadedFileData(uploadedImageData);
  
    } catch (error) {
      console.error('Image upload failed:', error);
    } finally {
      setIsLoading(false);
    }
  };
  
  
  const handleSendMessage = async () => {
    const userMessage = chatInputRef.current.value;
    if (selectedFile || userMessage.trim() !== '') {
      setIsLoading(true);
      setTyping(true); // Start typing animation
  
       // Add the user's message (and image if available) to chatMessages
       const newMessage = {
        message: userMessage,
        isResponse: false,
        image: uploadedFileData,
      };
      setChatMessages(prevMessages => [...prevMessages, newMessage]);
  
      // Clear the text area and reset states
      chatInputRef.current.value = '';
      setSelectedFile(null);
      setUploadedFileData(null);
      setPlaceholderText("Generating...");
  
      if (selectedFile) {
        // If there's a file, send it to the vision API
        try {
          const formData = new FormData();
          formData.append('file', selectedFile);
  
          const response = await fetch('https://tekt-sketch.replit.app/generate-image', {
            method: 'POST',
            body: formData,
          });
  
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
  
          let data = await response.json();
  
          // Process the data to remove unwanted characters
          let processedResponse = JSON.stringify(data, null, 2);
          processedResponse = processedResponse.replace(/^"|"$/g, '').replace(/\\n/g, '');
  
          // Add the processed response to chat messages
          setChatMessages(prevMessages => [
            ...prevMessages,
            { message: processedResponse, isResponse: true }
          ]);
        } catch (error) {
          console.error('Image upload failed:', error);
          setChatMessages(prevMessages => [
            ...prevMessages,
            { message: `Error analyzing image: ${error.message}`, isResponse: true }
          ]);
        }
      } else if (userMessage.trim() !== '') {
        // Fetch response for userMessage
        try {
          const chatResponse = await fetch(`https://tekt-chatbot.replit.app/chat/${userMessage}`);
          const chatData = await chatResponse.json();
  
          // Update chatMessages with the bot's response
          setChatMessages(prevMessages => [
            ...prevMessages,
            { message: chatData.response, isResponse: true },
          ]);
        } catch (error) {
          console.error('Failed to fetch chatbot response:', error);
          setChatMessages(prevMessages => [
            ...prevMessages,
            { message: "Error fetching response", isResponse: true },
          ]);
        }
      }
      setTyping(false);
      setIsLoading(false);
      setPlaceholderText("Enter a prompt for instant architectural response...");
    }
  };
  


  const fetchAudioForMessage = async (message) => {
    try {
        const ttsResponse = await fetch(`https://tekt-chatbot.tinaghazia.repl.co/tts`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ text: message })
        });

        if (ttsResponse.ok) {
            const ttsData = await ttsResponse.json();
            return ttsData.audioURL;  // Direct URL from the server
        } else {
            console.error('Failed to fetch TTS response');
            return null;
        }
    } catch (error) {
        console.error('Failed to fetch TTS response:', error);
        return null;
    }
};

 
  const handleSuggestionClick = async (suggestion) => {
    setIsLoading(true);
    // 1. Add the clicked suggestion to the chat messages
    setChatMessages((prevMessages) => [
      ...prevMessages,
      { message: suggestion, isResponse: false },
      { message: '', isResponse: true, isTyping: true },
    ]);

    // 2. Update the suggestion questions list
    setSuggestionQuestions((prevSuggestions) => {
      const unusedSuggestions = ALL_SUGGESTIONS.filter((q) => !prevSuggestions.includes(q));
      if (unusedSuggestions.length === 0) {
        return prevSuggestions.filter((q) => q !== suggestion);
      }
      const newSuggestion = unusedSuggestions[Math.floor(Math.random() * unusedSuggestions.length)];
      return [...prevSuggestions.filter((q) => q !== suggestion), newSuggestion];
    });

    // 3. Fetch the chatbot's response for the selected suggestion
    try {
      const response = await fetch(`https://tekt-chatbot.replit.app/chat/${suggestion}`);
      const data = await response.json();

      // 4. Update the chat messages to show the chatbot's response
      setChatMessages((prevMessages) => [
        ...prevMessages.slice(0, prevMessages.length - 1), // Removes the "isTyping" message
        { message: data.response, isResponse: true, audioURL: data.audioURL } // Adds the bot's response directly
      ]);
      setIsLoading(false);
    } catch (error) {
      console.error('Failed to fetch chatbot response:', error);
      setIsLoading(false);
    }
  };


  const handleSignOut = async () => {
    try {
      await auth.signOut();
      navigate('/');
    } catch (error) {
      alert(error.message);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage();
    }
  };

  const typeResponse = (message, audioURL = null) => {
    setTyping(true);
    let i = 0;
    typeInterval.current = setInterval(() => {
      setChatMessages((prevMessages) => {
        let messages = [...prevMessages];
        let lastMessage = { ...messages[messages.length - 1] };
        lastMessage.message = message.substring(0, i+1);
        messages[messages.length - 1] = lastMessage;
        return messages;
      });
      i++;
      if (i >= message.length) {
        clearInterval(typeInterval.current);
        setTyping(false);
        setChatMessages((prevMessages) => {
          let messages = [...prevMessages];
          let lastMessage = { ...messages[messages.length - 1] };
          lastMessage.isTyping = false;
          messages[messages.length - 1] = lastMessage;
          return messages;
        });
      }
    }, 25);
    if (i >= message.length) {
      clearInterval(typeInterval.current);
      setTyping(false);
      setChatMessages((prevMessages) => {
        let messages = [...prevMessages];
        let lastMessage = { ...messages[messages.length - 1] };
        lastMessage.isTyping = false;
        if (audioURL) lastMessage.audioURL = audioURL;  // Include the audio URL if present
        messages[messages.length - 1] = lastMessage;
        return messages;
      });
    }
  };
  
  const stopTyping = () => {
    clearInterval(typeInterval.current);
    setTyping(false);
    setChatMessages((prevMessages) => {
      let messages = [...prevMessages];
      let lastMessage = { ...messages[messages.length - 1] };
      lastMessage.isTyping = false;
      messages[messages.length - 1] = lastMessage;
      return messages;
    });
    setIsLoading(false);
  };
  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };
  
  const handleInputChange = (e) => {
    const capitalized = capitalizeFirstLetter(e.target.value);
    e.target.value = capitalized;
  };


  return (
    <div className="box-container">
      <button 
          className="sidebar-icon" 
          onClick={() => setSidebarVisible(!isSidebarVisible)}
          disabled={isLoading}
          style={{
              backgroundImage: `url(${isSidebarVisible ? menuCloseIcon : menuOpenIcon})`,
              backgroundColor: isSidebarVisible ? 'rgba(0, 0, 0, 0.11)' : 'rgba(255, 255, 255, 0.5)'
          }}
      >
      </button>

        <div id="side-bar" style={{ display: isSidebarVisible ? 'block' : 'none' }}>
          <ul>
          <li className={location.pathname === '/DashboardChatbot' ? 'active-link' : ''}>
              <Link to="/DashboardChatbot"><p className="sidebarlinks">💬</p> Architekt</Link>
          </li>
          <li className={location.pathname === '/DashboardImageGenerator' ? 'active-link' : ''}>
              <Link to="/DashboardImageGenerator"><p className="sidebarlinks">🏞️</p> Visuals</Link>
          </li>
          <li className={location.pathname === '/Interiors' ? 'active-link' : ''}>
              <Link to="/Interiors"><p className="sidebarlinks">🛋️</p> Interiors</Link>
          </li>
          <li className={location.pathname === '/DashboardExteriors' ? 'active-link' : ''}>
                <Link to="/DashboardExteriors"><p className="sidebarlinks">🏡</p> Exteriors</Link>
          </li>
          <li className={location.pathname === '/#' ? 'active-link' : ''}>
              <Link to="/"><p className="sidebarlinks">📍</p>Home</Link>
          </li>

          <li>
            <Link 
              to="/" 
              onClick={(e) => {
                e.preventDefault(); 
                handleSignOut();
              }}
            >
              <p className="sidebarlinks">🚪</p>Log out</Link>
          </li>
        </ul>
        </div>
      <div className="right-side" style={{ justifyContent: isSidebarVisible ? 'flex-start' : 'center' }}>
        <div className="prompt-container">
          <div className="content-wrapper">
          <div id="chat-box" ref={chatBoxRef}>
              {chatMessages.map((chat, index) => (
                <div key={index} className={chat.isResponse ? 'bot-message' : 'user-message'}>
                  {!chat.isResponse && (
                    <div>
                      <p>{chat.message}</p>
                      {chat.image && (
                        <img src={chat.image.url} alt={chat.image.name} className="chat-image" />
                      )}
                    </div>
                  )}
                  {chat.isResponse && (
                    <>
                      <div className="bot-response-container">
                        {chat.isTyping && !typing ? (
                          <span className="typing-animation"><span></span></span>
                        ) : (
                          <p>{chat.message}</p>
                        )}
                      </div>
                      <div className="bot-response-player">
                      {chat.message !== '' && (
                        <button onClick={() => handleAudioClick(chat)}>
                          {isFetchingAudio === chat.id
                            ? <span className="typing-animation"><span></span></span> // Typing animation for this message
                            : <a className="speaker-icon">🔉</a> // Speaker icon
                          }
                        </button>
                      )}
                      {playingAudioUrl === chat.audioURL && (
                        <audio className="audio-player" controls autoPlay src={playingAudioUrl} ref={audioRef} />
                      )}
                    </div>
                    </>
                  )}
                </div>
              ))}
            </div>

          {/* Display uploaded image name or suggestion questions */}
          {uploadedFileData ? (
            <div className="suggestion-questions">
              <span className="imagename">{uploadedFileData.name}</span>
                <button 
              className="remove-image-button" 
              onClick={() => setUploadedFileData(null)}
            >
              X
            </button>
            </div>
          ) : (
            <div className="suggestion-questions-container">
              <div className="suggestion-questions">
                {!typing && suggestionQuestions.map((question, index) => (
                  <p 
                    key={index} 
                    onClick={() => handleSuggestionClick(question)}
                    className={index >= 2 ? 'hide-on-mobile' : ''}
                  >
                    {question}
                  </p>
                ))}
                {typing && (
                  <button className="stop-button" onClick={stopTyping}>Stop</button>
                )}
              </div>
            </div>
          )}
            <section className="input-group">
              {/* Textarea */}
              <textarea
                className="prompt-input"
                placeholder={isLoading ? "Generating..." : placeholderText}
                disabled={isLoading}
                rows="1"
                ref={chatInputRef}
                onKeyPress={handleKeyPress}
                onChange={handleTextareaChange}
                onFocus={() => setTextAreaActive(true)}
                onBlur={() => setTextAreaActive(false)}
                onDrop={handleFileDrop}
              ></textarea>

            {/* Hidden File Input for Image Upload */}
            <input
              type="file"
              accept="image/*"
              onChange={handleImageUpload}
              style={{ display: 'none' }}
              ref={imageInputRef}
            />

            {/* Send and Upload Buttons */}
            <div className="send-button-container">
              <button 
                className="cta-button7" 
                id="askButton" 
                onClick={handleSendMessage}
                disabled={isLoading}
              >
                {renderSendIcon()}
              </button>

              <button
                  className="custom-upload-btn relative p-0"
                  aria-label="Attach files"
                  onClick={() => imageInputRef.current.click()}
                  disabled={isLoading}
                  style={{
                      color: isTextAreaActive ? "black" : "lightgray",
                      opacity: isLoading ? 0.55 : 1,
                  }}
              >

                <div className="flex w-full gap-2 items-center justify-center">
                    {/* SVG Icon */}
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M9 7C9 4.23858 11.2386 2 14 2C16.7614 2 19 4.23858 19 7V15C19 18.866 15.866 22 12 22C8.13401 22 5 18.866 5 15V9C5 8.44772 5.44772 8 6 8C6.55228 8 7 8.44772 7 9V15C7 17.7614 9.23858 20 12 20C14.7614 20 17 17.7614 17 15V7C17 5.34315 15.6569 4 14 4C12.3431 4 11 5.34315 11 7V15C11 15.5523 11.4477 16 12 16C12.5523 16 13 15.5523 13 15V9C13 8.44772 13.4477 8 14 8C14.5523 8 15 8.44772 15 9V15C15 16.6569 13.6569 18 12 18C10.3431 18 9 16.6569 9 15V7Z" fill="currentColor"></path>
                    </svg>
                </div>
            </button>
            <input
                multiple=""
                type="file"
                tabindex="-1"
                className="hidden"
                style={{ display: 'none' }}
                ref={imageInputRef}
                onChange={handleImageUpload}
            />

              {/* FAQ Link Button */}
              <Link to="/Faq" onClick={handleSendMessage}>
                {renderFaqIcon()}
              </Link>
            </div>
          </section>
              <p className="disclaimer">Architekt may produce inaccurate information about people, places, or facts.</p>
            </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardSketch;