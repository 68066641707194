import React from 'react';
import firebase from 'firebase/app';
import 'firebase/auth';
import Modal from 'react-modal';
import { useNavigate } from 'react-router-dom';
import './Login.css'; 
import { Link } from 'react-router-dom';

Modal.setAppElement('#root'); 

function Login({modalIsOpen, closeModal, openSignupModal}) {
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    const email = e.target.elements.email.value;
    const password = e.target.elements.password.value;
    try {
      await firebase.auth().signInWithEmailAndPassword(email, password);
      closeModal();
      navigate('./Launcher');
    } catch (error) {
      alert(error.message);
    }
  }

  const handleCreateAccountClick = () => { 
    closeModal();
    openSignupModal();
  }

  return (
    <Modal 
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      className="modal"
      overlayClassName="overlay"
    >
      <h2>Welcome back! 👋🏻</h2>
      <p className="need-account">Need a Tekt account? <span onClick={handleCreateAccountClick} className="account-link">Create an account</span></p>
      <form className="login-form" onSubmit={handleLogin} autocomplete="on">
        <button type="button" className="close-button" onClick={closeModal}>X</button>
        <input name="email" type="email" placeholder="Email" required autocomplete="email" />
        <input name="password" type="password" placeholder="Password" required autocomplete="current-password" />
        <button type="submit">Login</button>
      </form>
    </Modal>
  );
}

export default Login;
