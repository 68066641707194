import React, { useState, useRef, useEffect } from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import './DashboardChatbot.css';
import { auth } from '../firebase';
import backgroundImage from './images/background/tekt.png'; 
import assistantIcon from './images/dashboard/Assistant.png';
import profileIcon from './images/dashboard/Profile.png';
import historyIcon from './images/dashboard/History.png';
import settingIcon from './images/dashboard/setting-icon.png';
import botIcon from './images/dashboard/bot-icon.png';
import imageIcon from './images/dashboard/image-icon.png';
import interiorIcon from './images/dashboard/interior-icon.png';
import exteriorIcon from './images/dashboard/exterior-icon.png';
import infoIcon from './images/dashboard/info-icon.png';
import faqIconLightgray from './images/dashboard/faq-icon.png';
import faqIconBlack from './images/dashboard/faq-dark-icon.png';import menuOpenIcon from './images/dashboard/menu-Open-icon.png';
import menuCloseIcon from './images/dashboard/menu-Close-icon.png';

const ALL_SUGGESTIONS = [
  'Exterior of Suburban house',
  'Modern living room interior',
  'Glass domes',
  'Pink Barbie Gothic church',
  'Modular Glass Skyscraper',
  'Tell me about modern architecture.',
  'Modern Beachfront Villa at Sunset',
  'Shell-Inspired Residence',
  'Contemporary style architecture',
  'Pink Barbie Gothic church',
  'Lunetic dream of a future palace',
  'Exterior shot of utopian architecture building',
  'A space city',
  // ... [Add more if needed]
];
const botImageStylesLaptop = {
  maxWidth: '100%',
  backgroundColor: '#F1F0F0',
  color: 'black',
  padding: '20px',
  overflowX: 'hidden',
  gap: '10px',
  borderRadius: '25px',
  display: 'grid',
  gridTemplateColumns: 'repeat(2, 1fr)'
};

const botImageStylesMobile = {
  display: 'inline-block',
  float: 'left',
  fontSize: '15px',
  flexDirection: 'column',
  alignItems: 'start',
  backgroundColor: '#F1F0F0',
  color: 'black',
};

const DashboardImageGenerator = () => {
  const [suggestionQuestions, setSuggestionQuestions] = useState(
    ALL_SUGGESTIONS.slice(0, 5)
  );
  const [chatMessages, setChatMessages] = useState([]);
  const location = useLocation(); // Use location
  const [placeholderText, setPlaceholderText] = useState("Describe your image...");
  const chatInputRef = useRef(null);
  const chatBoxRef = useRef(null);
  const navigate = useNavigate();
  const isClient = typeof window === 'object';
  const [isSidebarVisible, setSidebarVisible] = useState(isClient ? window.innerWidth > 768 : false);
  const [isBotTyping, setIsBotTyping] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [isTextAreaActive, setTextAreaActive] = useState(false);

  
  const stopTyping = () => {
    setIsBotTyping(true);
  };
  
  const setVH = () => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  };

  const [isLaptopView, setIsLaptopView] = useState(window.innerWidth > 768); // Determining the view

  useEffect(() => {
    const handleResize = () => {
      setIsLaptopView(window.innerWidth > 768);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const setPlaceholderBasedOnWidth = () => {
    if (window.innerWidth <= 400) {
      setPlaceholderText("Describe your image.");
    } else {
      setPlaceholderText("Describe your image...");
    }
  };

  useEffect(() => {
    setVH();
    window.addEventListener('resize', setVH);

    setPlaceholderBasedOnWidth();

    if (chatBoxRef.current) {
      chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
    }

    return () => {
      window.removeEventListener('resize', setVH);
    };
  }, [chatMessages]);

  useEffect(() => {
    window.addEventListener('resize', setPlaceholderBasedOnWidth);

    return () => {
      window.removeEventListener('resize', setPlaceholderBasedOnWidth);
    };
  }, []);

  useEffect(() => {
    if (isClient) {
      const handleResize = () => {
        setSidebarVisible(window.innerWidth > 768);
      };
      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }
  }, [isClient]);

  useEffect(() => {
    if (chatBoxRef.current) {
      chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
    }
  }, [chatMessages]);

  const handleTextareaChange = (e) => {
    const value = e.target.value;
    setTextAreaActive(value.length > 0); // Set active if there's text
    handleInputChange(e); // Your existing input change handler
  };

  // Modify the SVG to change color based on isTextAreaActive state
  const renderSendIcon = () => {
    let color, opacity;
  
    if (isLoading) {
      color = "lightgray";
      opacity = 0.55; // Half opacity when loading
    } else {
      color = isTextAreaActive ? "black" : "lightgray";
      opacity = 1; // Full opacity otherwise
    }
  
    return (
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" className="text-white dark:text-black">
        <path d="M7 11L12 6L17 11M12 18V7" stroke={color} strokeOpacity={opacity} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
      </svg>
    );
  };
  const renderFaqIcon = () => {
    let iconSrc, iconOpacity;
  
    if (isLoading) {
      // When response is being generated
      iconSrc = faqIconLightgray; // Use black icon
      iconOpacity = 0.5; // Reduced opacity
    } else if (isTextAreaActive) {
      // When textarea is active
      iconSrc = faqIconBlack; // Use black icon
      iconOpacity = 1; // Full opacity
    } else {
      // Default state
      iconSrc = faqIconLightgray; // Use light gray icon
      iconOpacity = 1; // Full opacity
    }
  
    return <img className="faq-icon" src={iconSrc} alt="faq icon" style={{ opacity: iconOpacity }} />;
  };  


  const handleSendMessage = () => {
      const userMessage = chatInputRef.current.value;
      if (userMessage !== '') {
          setInputValue('');
          setChatMessages(prevMessages => [...prevMessages, { message: userMessage, isResponse: false }]);
          setIsBotTyping(true);  
          setIsLoading(true);  // <-- Set isLoading to true here
          chatInputRef.current.value = '';
          fetch(`https://tekt-image-gen.replit.app/image_generate/${userMessage}`)
              .then(response => response.json())
              .then(data => {
                  setIsBotTyping(false);                 
                  setIsLoading(false);  // <-- Set isLoading to false here
                  typeResponse(data.image_urls);
              })
              .catch(error => {
                setIsBotTyping(false);
                setIsLoading(false);  // <-- Set isLoading to false here too (in case of error)
              });
      }
  };



  const handleSuggestionClick = (suggestion) => {
    setIsLoading(true);
    // Add the suggestion to the chat messages.
    setChatMessages((prevMessages) => [
      ...prevMessages,
      { message: suggestion, isResponse: false },
    ]);
  
    // Put the suggestion in the input field.
    chatInputRef.current.value = suggestion;
  
    // Update the suggestions.
    setSuggestionQuestions((prevSuggestions) => {
      const unusedSuggestions = ALL_SUGGESTIONS.filter((q) => !prevSuggestions.includes(q));
      if (unusedSuggestions.length === 0) {
        return prevSuggestions.filter((q) => q !== suggestion);
      }
      const newSuggestion = unusedSuggestions[Math.floor(Math.random() * unusedSuggestions.length)];
      return [...prevSuggestions.filter((q) => q !== suggestion), newSuggestion];
    });
  
    // Fetch the image.
    fetch(`https://tekt-image-gen.replit.app/image_generate/${suggestion}`)
    .then(response => response.json())
    .then(data => {
      typeResponse(data.image_urls);
      setIsLoading(false);  // <-- Set isLoading to false after fetching the result
    })
    .catch(error => {
      setIsLoading(false);  // <-- Also set isLoading to false in case of an error
    });
  };
  

  const handleSignOut = async () => {
    try {
      await auth.signOut();
      // Remove the alert so it directly navigates to the homepage
      navigate('/');  // redirect to homepage after successful sign-out
    } catch (error) {
      alert(error.message);
    }
  };
  
  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage();
    }
  };
  const typeResponse = (imageUrls) => {
    setChatMessages((prevMessages) => [
      ...prevMessages,
      { imageUrls, isResponse: true },
    ]);
  };
  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };
  
  const handleInputChange = (e) => {
    const { value } = e.target;
    // Capitalize the first letter of the input value if it's not already capitalized
    const capitalizedValue = value.length === 0
      ? value
      : value.charAt(0).toUpperCase() + value.slice(1);
    
    // Update the state with the capitalized value
    setInputValue(capitalizedValue);
  };
  

  return (
    <div className="box-container">
      <button 
        className="sidebar-icon" 
        onClick={() => setSidebarVisible(!isSidebarVisible)}
      >       
      </button>

      <div id="side-bar" style={{ display: isSidebarVisible ? 'block' : 'none' }}>
          <ul>
          <li className={location.pathname === '/DashboardChatbot' ? 'active-link' : ''}>
              <Link to="/DashboardChatbot"><p className="sidebarlinks">💬</p> Architekt</Link>
          </li>
          <li className={location.pathname === '/DashboardImageGenerator' ? 'active-link' : ''}>
              <Link to="/DashboardImageGenerator"><p className="sidebarlinks">🏞️</p> Visuals</Link>
          </li>
          <li className={location.pathname === '/Interiors' ? 'active-link' : ''}>
              <Link to="/Interiors"><p className="sidebarlinks">🛋️</p> Interiors</Link>
          </li>
          <li className={location.pathname === '/DashboardExteriors' ? 'active-link' : ''}>
                <Link to="/DashboardExteriors"><p className="sidebarlinks">🏡</p> Exteriors</Link>
          </li>
          <li className={location.pathname === '/#' ? 'active-link' : ''}>
              <Link to="/"><p className="sidebarlinks">📍</p>Home</Link>
          </li>

          <li>
            <Link 
              to="/" 
              onClick={(e) => {
                e.preventDefault(); 
                handleSignOut();
              }}
            >
              <p className="sidebarlinks">🚪</p>Log out</Link>
          </li>
        </ul>
        </div>
      <div className="right-side">
        <div className="prompt-container">
          <div className="content-wrapper">
            <div id="chat-box" ref={chatBoxRef}>
              {chatMessages.map((chat, index) => (
                <div key={index} className={chat.isResponse ? 'bot-image' : 'user-message'}>
                  {chat.isResponse && (
                    <div style={isLaptopView ? botImageStylesLaptop : botImageStylesMobile}>
                      {chat.imageUrls.map((url, index) => (
                        <img key={index} src={url} alt="response" />
                      ))}
                    </div>
                  )}
                  {!chat.isResponse && <p>{chat.message}</p>}
                </div>
              ))}
            </div>
            <div className="suggestion-prompts-container">
              <div className="suggestion-prompts">
                {suggestionQuestions.map((question, index) => (
                  <p
                    key={index} 
                    onClick={() => handleSuggestionClick(question)}
                    className={index >= 2 ? 'hide-on-mobile' : ''}
                  >
                    {question}
                  </p>
                ))}
              </div>
            </div>
            <section className="input-group">
              <textarea
                className="prompt-input"
                id="prompt"
                placeholder={isLoading ? "Generating..." : placeholderText}
                rows="1"
                ref={chatInputRef}
                onKeyPress={handleKeyPress}
                value={inputValue}
                onChange={handleInputChange} // Updated this line
                disabled={isLoading}
                onFocus={() => setTextAreaActive(true)}
                onBlur={() => setTextAreaActive(false)}
              ></textarea>
              <div className="send-button-container">
              <button 
                  className="cta-button7" 
                  id="askButton" 
                  onClick={handleSendMessage} 
                  disabled={isLoading}
                  style={{
                    color: isLoading || !isTextAreaActive ? 'white' : 'black',
                    opacity: isLoading ? 0.5 : 1
                  }}
                >
                  {renderSendIcon()}
                </button>
                <Link to="/Faq" onClick={handleSendMessage}>
                  {renderFaqIcon()}
                </Link>
                </div>
            </section>
            <p className="disclaimer">Architekt may produce inaccurate information about people, places, or facts.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardImageGenerator;
