import React, { useState, useEffect } from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import SignUp from './SignUp';
import Login from './Login';
import EditProfile from './EditProfile'; 
import Modal from 'react-modal';
import './Header.css';
import logo from './images/header/tekt_logo.png';
import { useAuth } from '../hooks/useAuth'; 
import InstagramIcon from './images/footer/insta-icon.png';
import TwitterIcon from './images/footer/twitter-icon.png';
import LinkdinIcon from './images/footer/linkdin-icon.png';
import TiktokIcon from './images/footer/tiktok-icon.png';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import { auth } from '../firebase';

function Header({ className, isAuthenticated }) {
  const location = useLocation();
  const isHomePage = location.pathname === '/';
  const defaultClass = className || "header";
  const headerClass = 'header-non-fixed';

  const { 
    signupModalIsOpen,
    openSignupModal,
    closeSignupModal,
    loginModalIsOpen,
    openLoginModal,
    closeLoginModal
  } = useAuth();

  const [menuOpen, setMenuOpen] = useState(false);
  const [profileModalIsOpen, setProfileModalIsOpen] = useState(false);
  const [editProfileModalIsOpen, setEditProfileModalIsOpen] = useState(false);
  const [user, setUser] = useState(null);

  const openProfileModal = () => setProfileModalIsOpen(true);
  const closeProfileModal = () => {
    console.log("Closing profile modal...");  // For debugging
    setProfileModalIsOpen(false);
  };
    const openEditProfileModal = () => {
    closeProfileModal();
    setEditProfileModalIsOpen(true);
  }
  const closeEditProfileModal = () => setEditProfileModalIsOpen(false);
  const navigate = useNavigate();
  const handleSignOut = async () => {
    try {
      await auth.signOut();
      closeProfileModal(); // Close the profile modal after signing out
      navigate('/');  // redirect to homepage after successful sign-out
    } catch (error) {
      alert(error.message);
    }
  };
  

  useEffect(() => {
    const currentUser = firebase.auth().currentUser;
    if (currentUser) {
      const userRef = firebase.firestore().collection('users').doc(currentUser.uid);
      userRef.get().then(doc => {
        if (doc.exists) {
          setUser(doc.data());
        }
      });
    }
  }, [profileModalIsOpen]);

  return (
    <>
      <header className={headerClass}>
        <button className="menu-btn" onClick={() => setMenuOpen(!menuOpen)}>
            {menuOpen ? 'X' : <a href="javascript:void(0);" id="menu">&#9776;</a>}
        </button>
        <Link to="/" id="logo-link">
            <img src={logo} id="logo" className="logo" alt="Logo" />
        </Link>
        <nav className="nav">
            {isAuthenticated ? (
              <>
                <button className="profile-link" onClick={openProfileModal}>Profile</button>
              </>
            ) : (
              <>
                <button className="login-link" onClick={openLoginModal}>Login</button>
                <button className="signup-link" onClick={openSignupModal}>Continue</button>
              </>
            )}
        </nav>
        <SignUp modalIsOpen={signupModalIsOpen} closeModal={closeSignupModal} openLoginModal={openLoginModal} />
        <Login modalIsOpen={loginModalIsOpen} closeModal={closeLoginModal} openSignupModal={openSignupModal} />
      </header>
      
      <Modal 
        isOpen={profileModalIsOpen}
        onRequestClose={closeProfileModal}
        className="modal"
        overlayClassName="overlay"
      >
        <h2>My Profile</h2>
        <button className="close-button" onClick={closeProfileModal}>X</button>

        {user && (
          <div className="My-info">
            <p><strong>Email:</strong> {user.email}</p>
            <p><strong>First Name:</strong> {user.firstName}</p>
            <p><strong>Last Name:</strong> {user.lastName}</p>
            <p><strong>Password:</strong> ••••••••</p>
            <div className="profilebuttons">
            <button onClick={openEditProfileModal}>Edit profile</button>
            <button>
              <Link 
                to="/" 
                onClick={(e) => {
                  e.preventDefault(); 
                  e.stopPropagation(); // Stop the event from propagating further
                  handleSignOut();
                }}
              >
                Sign out
              </Link>
            </button>
              </div>
          </div>
        )}

      </Modal>

      <EditProfile modalIsOpen={editProfileModalIsOpen} closeModal={closeEditProfileModal} openProfileModal={openProfileModal} />
      
      {/* Universal menu */}
      {menuOpen && (
          <div id="universal-menu">
                  <Link to="/AboutUs">About</Link>
                  <Link to="/Pricing">Pricing</Link>
              <a href="mailto:info@tekt.ai">Contact Us</a>

              <div className="social-icons">
                {/* You would include your social icons here. Assuming something like this: */}
                <a href="https://instagram.com/tekt.ai" className="social-icon" target="_blank" rel="noopener noreferrer">
                        <img src={InstagramIcon} alt="Instagram" width="20" height="20" />
                </a>
                <a href="https://twitter.com/tekt_ai" className="social-icon" target="_blank" rel="noopener noreferrer">
                        <img src={TwitterIcon} alt="Twitter" width="20" height="20" />
                </a>
                    <a href="https://www.linkedin.com/company/tekt-ai/" className="social-icon" target="_blank" rel="noopener noreferrer">
                        <img src={LinkdinIcon} alt="Linkdin" width="20" height="20" />
                    </a>
                    <a href="https://www.tiktok.com/company/tekt.ai/" className="social-icon" target="_blank" rel="noopener noreferrer">
                        <img src={TiktokIcon} alt="Tiktok" width="20" height="20" />
                    </a>
              </div>
              <div className="account">
              {isAuthenticated ? (
                <button className="profile-link" onClick={openProfileModal}>Profile</button>
              ) : (
                <>
                  <button className="signup-link-menu" onClick={openSignupModal}>Sign Up</button>
                  <button className="login-link-menu" onClick={openLoginModal}>Login</button>
                </>
              )}
              </div>
          </div>
      )}
    </>
  );
}
export default Header;